import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import YouTube from 'components/youtube'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import LocationsSection from 'components/locations-section'
import AdditionalResources from 'components/new/additional-resources'
import Spacer from 'components/new/spacer'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H5 from 'components/new/typography/h5'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'

const values = [
  {
    title: 'Trust',
    description:
      'We promote unity with internal and external customers by delivering upon our promises and standards.',
  },
  {
    title: 'Commitment',
    description:
      "We're committed to providing our customers continuous improvement and innovation that drives operational excellence.",
  },
  {
    title: 'Integrity',
    description: 'We adhere to moral and ethical principles without compromise.',
  },
  {
    title: 'Reliability',
    description: "We're dependable and consistent in all things we do.",
  },
  {
    title: 'Customer Focus',
    description: 'We put customer’s needs before our own needs and preferences.',
  },
]

const productsServices = [
  {
    label: 'Equipment',
    title: 'High-quality equipment',
    description:
      'We sell and support a portfolio of top brands, including John Deere, STIHL and Manitou.',
    link: '/equipment/',
    linkText: 'Browse equipment',
  },
  {
    label: 'Parts',
    title: 'Right parts at the right price',
    description:
      'We carry a wide selection of OEM and aftermarket parts options for many makes and models.',
    link: '/parts/',
    linkText: 'Find parts',
  },
  {
    label: 'Service',
    title: 'Stay up and running',
    description:
      'Get high-quality service from our certified technicians on all types of equipment.',
    link: '/service/',
    linkText: 'Schedule service',
  },
  {
    label: 'Support',
    title: 'Optimize your operation',
    description: 'Learn more about our precision ag, agronomy and Connected Support services.',
    link: '/precision-ag/',
    linkText: 'View support services',
  },
]

const companyVideoSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'VideoObject',
  'name': 'Hutson Inc Company Video',
  'description':
    'Since its founding in 1928, the Hutson name has been coincident with innovative agricultural products and services. With a foundation built upon a strong commitment to customer service, we have sunk our roots deep in the community. From our humble beginnings more than 90 years ago, Hutson has gro...',
  'thumbnailUrl': 'https://i.ytimg.com/vi/XAF4TLZwANc/hqdefault.jpg',
  'uploadDate': '2018-09-26T20:37:29.000Z',
  'duration': 'PT2M29S',
  'embedUrl': 'https://youtube.googleapis.com/v/XAF4TLZwANc',
})

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'About',
      'item': 'https://www.hutsoninc.com/about/',
    },
  ],
})

const AboutPage = props => {
  const {
    data: {
      allSanityLocation: { totalCount: locationCount },
      heroImage,
      heroImageMobile,
      signImage,
      visionImage,
      missionImage,
    },
  } = props

  const heroImages = withArtDirection(getImage(heroImage), [
    {
      media: '(max-width: 599px)',
      image: getImage(heroImageMobile),
    },
  ])
  return (
    <Layout>
      <Helmet>
        <title>About Us | Hutson Inc</title>
        <script type='application/ld+json'>{companyVideoSchema}</script>
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <Hero title='About Us' image={heroImages} overlayOpacity={0.25} />

      <MainSection>
        <div className='column'>
          <Content kind='full'>
            <H2>Serving our local communities for over 90 years</H2>
            <P>
              Since its founding in 1928, the Hutson name has been coincident with innovative
              agricultural products and services. With a foundation built upon a strong commitment
              to customer service, we have sunk our roots deep in the community. From our humble
              beginnings more than 90 years ago, Hutson has grown and evolved to meet the growing
              needs of our customers. It is through our dedicated employees and customers that we
              have been able to diversify from a single location farming mercantile into one of the
              largest John Deere dealers in North America.
            </P>
            <P>
              In 1990, Hutson acquired its first John Deere dealership and through planned growth
              and a continuous focus on customer service, Hutson has grown into {locationCount}{' '}
              locations throughout the heartland of the United States. The Hutson product offering
              has grown over the years to include Lawn & Garden, Commercial Worksite, Ag Management
              Solutions, and Commercial Application Products. Recently, Hutson has added new
              divisions to provide agronomic support for our customers. We pride ourselves in being
              able to provide the highest quality support and service that our customers expect and
              come back for year after year.
            </P>
            <P>
              Our people are passionate individuals who get involved and make a difference in the
              lives of our customers and our communities every day. We stand for putting our
              customers first, treating people with respect, and thinking creatively to solve
              problems. We believe strongly in supporting our communities through school city and
              regional events.
            </P>
          </Content>
        </div>
        <div className='column'>
          <div className='sign-image-container'>
            <GatsbyImage
              image={getImage(signImage)}
              objectFit='contain'
              objectPosition='center bottom'
              style={{ width: '100%' }}
              alt='Hutson Inc John Deere sign'
            />
          </div>
        </div>
      </MainSection>
      <FlexGrid reverse>
        <div>
          <GatsbyImage
            image={getImage(missionImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt='Hutson employee giving a presentation at the Field Day event.'
          />
        </div>
        <MissionContent>
          <SectionLabel>Our Mission</SectionLabel>
          <H3 as='h2'>Let's Grow Together</H3>
          <P>
            Our Mission Statement says it best, <strong>“Let's Grow Together”</strong>. Much like
            the growth Hutson has experienced, we want to help provide the solutions and support our
            customers need to grow their business, farm or property.
          </P>
        </MissionContent>
      </FlexGrid>
      <FlexGrid>
        <div>
          <GatsbyImage
            image={getImage(visionImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt='John Deere tractors lined up in the lot with the Hutson John Deere sign in the background.'
          />
        </div>
        <VisionContent>
          <SectionLabel>Our Vision</SectionLabel>
          <H3 as='h2'>To be the premier supplier...</H3>
          <P>
            The Hutson Vision is{' '}
            <strong>“To be the premier supplier of equipment, support and services”</strong>. We
            invest in our buildings, equipment and people to provide better service and support to
            our customers. We strive to be a trusted partner in business.
          </P>
        </VisionContent>
      </FlexGrid>
      <Content kind='full'>
        <SectionLabel>Our Values</SectionLabel>
        <H3 as='h2'>What sets us apart</H3>
        <P>
          Our Core Values guide us in every decision we make and action we take. These are not just
          words to us; they are the standards we represent.
        </P>
        <Spacer size='m' />
        <ValuesGrid>
          {values.map((value, index) => (
            <div className='column' key={value.title}>
              <SectionLabel>0{index + 1}</SectionLabel>
              <H5 as='h3'>{value.title}</H5>
              <P>{value.description}</P>
            </div>
          ))}
        </ValuesGrid>
      </Content>
      <VideoSection>
        <div className='column'>
          <div className='video-container'>
            <YouTube videoId='XAF4TLZwANc' />
          </div>
        </div>
        <div className='column'>
          <Content kind='full'>
            <H2>Hear what our team members have to say about working for Hutson</H2>
            <P>Learn what we're all about from some of our very own employees.</P>
            <Spacer size='m' />
            <Button as={ButtonLink} to='/careers/' color='yellow' ghost>
              Learn more about careers at Hutson
            </Button>
          </Content>
        </div>
      </VideoSection>
      <Content kind='full'>
        <H2>Our products and services</H2>
        <Spacer size='m' />
        <ProductsServicesGrid>
          {productsServices.map(obj => (
            <div className='column' key={obj.label}>
              <SectionLabel>{obj.label}</SectionLabel>
              <H3>{obj.title}</H3>
              <P>{obj.description}</P>
              <Spacer size='s' />
              <Button as={ButtonLink} to={obj.link} color='green' ghost>
                {obj.linkText}
              </Button>
            </div>
          ))}
        </ProductsServicesGrid>
      </Content>
      <LocationsSection />
      <AdditionalResources
        resources={[
          {
            title: 'Get in touch',
            links: [
              <Link to='/contact/'>Contact Us</Link>,
              <Link to='/sponsorship/'>Sponsorship request</Link>,
              <Link to='/transfer/'>Transfer request</Link>,
              <Link to='/loaner/'>Equipment loan request</Link>,
            ],
          },
          {
            title: 'News & announcements',
            links: [
              <Link to='/blog/'>Visit our blog</Link>,
              <Link to='/blog/news/'>Read our latest news</Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 600px) {
    height: 350px;
  }

  @media (min-width: 900px) {
    height: 450px;
  }
`

const MainSection = styled.div`
  position: relative;

  .column {
    &:first-child {
      z-index: 1;
      position: relative;
    }

    &:last-child {
      bottom: 0;
      position: absolute;
      width: 200px;
      opacity: 0.08;
      right: 24px;
    }
  }

  img {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
  }

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;

    .column {
      &:first-child {
        ${column('9/12')}
      }

      &:last-child {
        ${column('3/12')}
        position: relative;
        overflow: hidden;
        opacity: 1;
      }
    }

    .sign-image-container {
      display: flex;
      height: 100%;
      left: 0;
      padding: 36px 36px 0 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    img {
      mask-image: none;
    }
  }
`

const SectionLabel = styled.span`
  color: ${props => props.theme.color.g400};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const MissionContent = styled(FlexGridTextColumn)`
  background-color: ${props => props.theme.color.g400};

  ${SectionLabel} {
    color: ${props => props.theme.color.y400};
  }

  h2 {
    color: #fff;
  }

  p {
    color: ${props => props.theme.color.g50};
  }
`

const VisionContent = styled(FlexGridTextColumn)`
  background-color: ${props => props.theme.color.n20};
`

const ValuesGrid = styled.div`
  ${clearfix}

  .column {
    background-color: ${props => props.theme.color.n10};
    border: 1px solid ${props => props.theme.color.n30};
    border-top: 4px solid ${props => props.theme.color.g400};
    margin-bottom: 16px;
    padding: 16px;

    ${SectionLabel} {
      margin-bottom: 8px;
    }
  }

  @media (min-width: 480px) {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    .column {
      ${column('1/2', 16)}
    }
  }

  @media (min-width: 600px) {
    .column {
      ${column('1/3', 16)}
    }
  }

  @media (min-width: 900px) {
    flex-wrap: nowrap;

    .column {
      ${column('1/5', 16)}
      margin-bottom: 0;
    }
  }
`

const VideoSection = styled.div`
  background-color: ${props => props.theme.color.n700};

  .video-container {
    background-color: ${props => props.theme.color.n900};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  h2,
  p {
    color: #fff;
  }

  @media (min-width: 900px) {
    display: flex;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }
  }
`

const ProductsServicesGrid = styled.div`
  ${clearfix};
  display: flex;
  flex-wrap: wrap;

  .column {
    background-color: ${props => props.theme.color.n20};
    margin-bottom: 24px;
    padding: 24px;
    width: 100%;
  }

  @media (min-width: 600px) {
    .column {
      ${column('1/2', '24px')};
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      .spacer {
        flex-grow: 2;
      }
    }
  }

  @media (min-width: 1000px) {
    .column {
      ${column('1/4', '24px')};
      margin-bottom: 0;
    }
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    allSanityLocation(filter: { status: { ne: "permanently-closed" } }) {
      totalCount
    }
    heroImage: file(relativePath: { eq: "hutson-williamston-storefront-angled.jpg" }) {
      ...FullWidthImage
    }
    heroImageMobile: file(relativePath: { eq: "hutson-williamston-storefront-angled-mobile.jpg" }) {
      ...SharpImage600
    }
    signImage: file(relativePath: { eq: "hutson-inc-john-deere-tall-sign.jpg" }) {
      ...SharpImage480
    }
    missionImage: file(relativePath: { eq: "andrew-field-day-talk.jpg" }) {
      ...SharpImage900
    }
    visionImage: file(relativePath: { eq: "paducah-tractor-lineup-sign.jpg" }) {
      ...SharpImage900
    }
  }
`

export default AboutPage
